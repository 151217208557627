LemonFrog.HomepageSearchForm = (function () {
  const moduleName = 'HomepageSearchForm',
      searchForm = '.js-home-page-cta-form',
      searchFormRadio = '.js-home-page-cta-form .radio';

  function init () {
    $(document).on('click', searchFormRadio, function() {
      const $input = $(this).children('input');

      if ($input.is(':checked')) {
        $(searchForm).attr('data-role', $input.data('role'));
      }
    });
  }

  return {
    moduleName: moduleName,
    init: init
  };
}());

LemonFrog.initModule(LemonFrog.HomepageSearchForm);
