import { updateQueryStringParameter } from '/helpers/url';

LemonFrog.HomepageRegisterForm = (function () {
  const moduleName = 'HomepageRegisterForm',
      moduleSel = `[data-module="${moduleName}"]`;

  function init () {
    $(moduleSel).find(".js-user-role").click(function () {
      const $link = $(this).parents(moduleSel).find(".js-start-now-link");
      const url = $link.attr("href");
      const newUrl = updateQueryStringParameter(url, "role", $(this).data("role"));

      $link.attr("href", newUrl);
    });
  }

  return {
    moduleName: moduleName,
    init: init
  };
}());

LemonFrog.initModule(LemonFrog.HomepageRegisterForm);
